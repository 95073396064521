import React, { useContext } from 'react'
import LOGO from '../assets/images/KyLogonewmetal.png';
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import TwitterIcon from './TwitterIcon';
import { Link } from 'react-router-dom';
import moment from 'moment';
import GlobalContext from '../contexts/store';

export default function Footer() {
  const { company } = useContext(GlobalContext);

  return (
    <footer id="my-footer" className="footer" data-background-color="black">
      <div className="container flex items-center justify-between mb-10">
        <img style={{ marginTop: -8 }} height={95} width={150} className='footer-brand' src={LOGO} alt='Logo' />
        <ul className="flex gap-4 items-center justify-center">
          <li className={"nav-link"}>
            &copy; {moment().format('YYYY')} {company?.name}. All Rights Reserved.
          </li>
        </ul>

        <ul className="flex gap-2 pr-32 hidden xl:flex lg:flex pull-right">
          <Link
            to={'/'}
            className="text-sm duration-500 text-zinc-200 hover:text-zinc-500"
          >
            <FaFacebookSquare size={24} />
          </Link>

          <Link
            to={'/'}
            className="text-sm duration-500 text-zinc-200 hover:text-zinc-500"
          >
            <FaInstagram size={24} />
          </Link>

          <Link
            to={'/'}
            className="text-sm duration-500 text-zinc-200 hover:text-zinc-500"
          >
            <TwitterIcon size={24} />
          </Link>
        </ul>
      </div>
    </footer>
  )
}
