import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PageContainer from '../components/PageContainer';
import Subscribe from '../components/Subscribe';
import { Link } from 'react-router-dom';
import GlobalContext from '../contexts/store';
import { Get } from '../api/server';
import ImageContentCard from '../components/ImageContentCard';
import moment from 'moment';
import AD from '../assets/images/415Banner.jpg';

export default function App() {
  const { company } = useContext(GlobalContext);
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    async function getData() {
      if (company?._id) {
        const res = await Get(`/events/getPromoBanners/${company?._id}`);
        setUpcomingEvents(res.slice(0, 3));
      }
    }
    getData();
  }, [company]);

  return (
    <>
      <Container className='mb-10'>
        <Row>
          <Col className='' style={{ marginTop: 50 }} md={12}>
            <div className="flex flex-col items-center justify-center w-full h-screen mt-30">
              <div className="hidden w-screen h-px animate-glow md:block animate-fade-left bg-gradient-to-r from-zinc-300/0 via-zinc-300/50 to-zinc-300/0" />
              <h1 style={{ fontWeight: 800 }} className="uppercase sm:mt-50 z-10 mx-auto max-w-4xl font-display text-5xl font-black tracking-normal text-gray-300 sm:text-6xl text-center duration-1000 animate-title font-display">
                FOLSOM FAIR WEEKEND 2024
              </h1>
              <h1 className="mx-auto max-w-4xl font-display text-3xl font-bold tracking-normal text-gray-300 sm:text-4xl text-center duration-1000 animate-title font-display">
                <span className="relative whitespace-nowrap text-blue-600">
                  <span className="relative"> 2 Party Pass Available </span>
                </span>{" "}
              </h1>
              <div className="hidden w-screen h-px animate-glow md:block animate-fade-right bg-gradient-to-r from-zinc-300/0 via-zinc-300/50 to-zinc-300/0" />
              {/* <div className="my-16 mt-30 text-center animate-fade-in">
                <h2 className="mt-30 text-sm text-zinc-500 ">
                  We are pround to announce that is under new management. <br /> We are also pround to announce that we are now 21+.
                </h2>
              </div> */}
              <Link
                className="latin bg-blue-600 rounded-xl text-white font-medium px-4 py-3 sm:mt-10 mt-8 hover:bg-blue-500 transition"
                to="/events"
              >
                Purchase Tickets
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
      <PageContainer>
        <Row>
          <Col style={{ marginBottom: 20 }} md={12}>
            <ImageContentCard
              name={'415'}
              image={AD}
              link={'https://heymistr.com/'}
              info={'Official sponsor of Ky Martinez Events'}
              position={'top'}
            />
          </Col>
          {upcomingEvents.length > 0 && upcomingEvents.map((event, index) => (
            <Col key={index} style={{ marginBottom: 20 }} md={upcomingEvents.length > 1 ? 6 : 12}>
              <ImageContentCard
                name={event?.name}
                image={event?.promo?.post || event?.promo?.story}
                link={'/events/' + event?.slug}
                info={moment(event?.start_date).format('lll')}
                position={'center'}
                minH={'700px'}
              />
            </Col>
          ))}
          <Col md={12} className="mb-10">
            <ImageContentCard
              name={'hybrid ink'}
              image={'https://static.wixstatic.com/media/944f70_bf3ca7e74a294749b3ccca48ef2a5b47~mv2.jpeg/v1/fill/w_1200,h_1542,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/HYBRIDINK.jpeg'}
              link={'https://hybridink.net/'}
              info={'Solve your Hair Loss Problem'}
              position={'top'}
            />
          </Col>
        </Row>
        <Subscribe />
      </PageContainer>
    </>
  )
}