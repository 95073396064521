import React from 'react'
import { Container } from 'react-bootstrap'

export default function PageContainer({ children, className }) {
  return (
    <div className={`p-3 sm:p-5 pb-20 w-full ${className}`}>
      { children }
    </div>
  )
}
