import Contact from '../pages/Contact';
import Landing from '../pages/Landing';
import Conformation from '../pages/events/Conformation';
import Event from '../pages/events/Event';
import EventList from '../pages/events/EventList';
import Gallery from '../pages/gallery/Gallery';
import GalleryList from '../pages/gallery/GalleryList';

const routes = [
  {
    name : 'Home',
    path : '/',
    outside : false,
    exact : true,
    Element: <Landing />
  },
  {
    name : 'Photos',
    path : '/galleries',
    outside : false,
    exact : true,
    Element: <GalleryList />
  },
  {
    name : 'Gallery',
    path : '/galleries/:slug',
    outside : false,
    exact : true,
    dynamic: true,
    Element: <Gallery />
  },
  {
    name : 'Events',
    path : '/events',
    outside : false,
    exact : true,
    Element: <EventList />
  },
  {
    name : 'Event',
    path : '/events/:slug',
    outside : false,
    exact : true,
    dynamic: true,
    Element: <Event />
  },
  {
    name: 'Conformation',
    path: '/events/confirmation/:user/:event',
    outside: false,
    exact: true,
    dynamic: true,
    Element: <Conformation />
  },
  {
    name : 'Contact',
    path : '/contact',
    outside : false,
    exact : true,
    Element: <Contact />
  }
]

export default routes;