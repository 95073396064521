import moment from 'moment'
import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import LOGO from '../assets/images/logo512.png';

function Ticket({ ticket, company, bgColor}) {
  return (
    <Card style={{backgroundColor: bgColor ? bgColor : 'white'}} className={`${ticket.scanid} ticket-card`}>
      {/* Banner */}
      <Card.Img variant="top" src={ticket.event.promo.banner} alt="Banner" />
      {/* Ticket information */}
      <Card.Body style={{marginBottom: 20}}>
        <Container className="my-3">
          <Row className="justify-content-center">
            <QRCodeSVG imageSettings={{
              src: LOGO,
              height: 50,
              width: 50,
              excavate: false,
            }} value={ticket.scanid} bgColor='#00000' fgColor="#2662ea" renderAs="svg" />
          </Row>
        </Container>
        <Row>
          <hr style={{ borderColor: '#f542e95e', borderStyle: 'dotted' }} />
          <Col>
            <div className="d-flex flex-column align-items-center justify-content-between text-white">
              <Card.Text className='text-white' style={{ fontFamily: 'mainFont', fontSize: '1.0rem', color: 'white !important' }}>{ticket.event.name}</Card.Text>
              <Card.Text className="text-white">{ticket.ticketInventory.ticketName}</Card.Text>
              <Card.Text className="text-white"><b>{ticket?.first_name} {ticket?.last_name}</b></Card.Text>
              <Card.Text>{moment(ticket.event.start_date).format('lll')}</Card.Text>
              <div className="spacer"></div>
              <Card.Text className="text-white" style={{ fontFamily: 'mainFont', fontSize: 18 }}>
                {ticket?.admitted ? 'Scanned!' : ''}
              </Card.Text>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Ticket