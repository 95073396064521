import React from 'react'
import { Col, Row } from 'react-bootstrap';

function BasicHeader({ logo, byline, headerimage, size }) {
  

  return (
    <div className="page-header page-header-small header-filter">
      <div
        className="page-header-image"
        style={{
          backgroundImage: `url(${headerimage})`,
          height:500,
          width: '100%'
        }}
      />
      <div className="content-center">
        <Row>
          <Col className="ml-auto mr-auto text-center" md="6">
            <div className="text-center">
              {logo && (
                <img
                  style={{ maxHeight: 300 }}
                  className='basic-header-logo'
                  src={logo}
                  alt='logo' />

              )}
            </div>
          </Col>
          <Col sm={12}>
            <h4 style={{ fontFamily: 'mainFont', fontSize: size ? size : 24 }} className='text-center basic-header-text text-white'>{byline}</h4>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BasicHeader