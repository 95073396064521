import React, { useContext, useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { Get } from '../../api/server';
import GlobalContext from '../../contexts/store';
import moment from 'moment';

export default function EventList() {
  const { company } = useContext(GlobalContext)
  const [pageData, setPageData] = useState([]);
  const [nothingToSee, setNothingToSee] = useState(false);


  useEffect(() => {
    async function getData() {
      try {
        const response = await Get(`/events/getbyslug/ky_martinez_events/${company?._id}`);
        if (response.length > 0) {
          setPageData(response);
        } else {
          setNothingToSee(true);
        }
      } catch (err) {
        console.log('page error', err);
      }
    }
    getData();
  }, [company]);


  const EventCard2 = ({ event }) => {
    return (
      <Col md={3} className=''>
        <div style={{maxHeight: 371}} className="card profile-card-1 mb-10 hover:border-blue-700">
          <img src={event?.promo?.story || event?.promo?.post} alt="profile-sample1" className="background hover:blur-none" width={500} height={500} />
          <img style={{bottom: "47% !important"}} src={event?.promo?.story || event?.promo?.story} alt="profile" className="profile" width={500} height={500} />
          <div className="card-content">
            <h2 className='text-white'>{event?.name}<small>{moment(event.start_date).format('lll')}</small></h2>
            <Link to={`/events/${event?.slug}`} className="btn btn-primary btn-round">View Event</Link>
          </div>
        </div>
      </Col>

    )
  }

  return (
    <>
      {/* <div className="page-header page-header-small header-filter mb-20">
        <PromoSwiper events={pageData} />
      </div> */}
      <div className='w-100'>
        <h1 style={{ fontWeight: 800 }} className="py-36 uppercase sm:mt-50 z-10 mx-auto max-w-4xl font-display text-5xl font-black tracking-normal text-gray-300 sm:text-6xl text-center duration-1000 animate-title font-display ">
          Upcoming Events
        </h1>
      </div>

      {nothingToSee && (<div className="page-header page-header-small header-filter mb-20 justify-center items-center">
        <h1 className='text-white text-center text-4xl'>No Events at this time</h1>
      </div>)}
      <div className="main main-raised">
        <div className="container min-h-screen">
          <div className="row">
            {pageData && Array.isArray(pageData) && pageData.length > 0 ? pageData.map((data, index) => (
              <EventCard2 event={data} key={index} />
            )) : null}
          </div>
        </div>
      </div>
    </>
  )
}
