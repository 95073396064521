import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import LOGO from '../assets/images/KyLogonewmetal.png';
import routes from '../routes';
import { Link } from 'react-router-dom';
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { FcMenu } from "@react-icons/all-files/fc/FcMenu";
import TwitterIcon from './TwitterIcon';

const SideMenu = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div style={{ cursor: 'pointer', zIndex: 200 }} onClick={handleShow}>
        <FcMenu size={30} color='white' />
      </div>

      <Offcanvas style={{ width: '300px', backgroundColor: '#232934', color: 'white' }} show={show} onHide={handleClose}>
        <Offcanvas.Header>
          <Offcanvas.Title>
            <img
              src={LOGO}
              alt='logo'
              width={100}
              className='ml-5 mt-2'
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <hr className='border-gray-500' />
        <Offcanvas.Body>
          <ul>
            {routes.map((route, key) => {

              return !route.dynamic ? (
                <li key={key} className="mb-4">
                  <Link
                    className='text-white'
                    to={route.path}
                    onClick={handleClose}
                  >
                    {route.name}
                  </Link>
                </li>
              ) : null
            })}

          </ul>
          <ul className="flex gap-2">
            <Link
              href={'/'}
              className="text-sm duration-500 text-zinc-500 hover:text-zinc-300"
            >
              <FaFacebookSquare size={24} />
            </Link>

            <Link
              href={'/'}
              className="text-sm duration-500 text-zinc-500 hover:text-zinc-300"
            >
              <FaInstagram size={24} />
            </Link>

            <Link
              href={'/'}
              className="text-sm duration-500 text-zinc-500 hover:text-zinc-300"
            >
              <TwitterIcon className="text-sm duration-500 text-zinc-500 hover:text-zinc-300" size={24} />
            </Link>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default SideMenu