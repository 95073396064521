const clubx = [
  "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4517.JPG",
  "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4468.JPG",
  "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4456.JPG",
  "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4500.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4415.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4542.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4549.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4510.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4541.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4533.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4465.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4485.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4513.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4501.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4405.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4431.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4447.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4487.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4560.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4545.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4508.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4553.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4430.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4473.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4414.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4446.JPG",
  // "https://sfclubs.s3-us-west-1.amazonaws.com/galleryItems/1679474140350_@Crispy.Raw-4538.JPG"
];

export { clubx };

