import { useEffect, useState } from 'react';

function Thumbnails({ images }) {
  const [mainImage, setMainImage] = useState(images[0]);

  useEffect(() => {
    // get the first image from the array
    setMainImage(images[0]);
  }, [images]);

  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        height: 675,
      }} className="p-4">
        {mainImage ? (
            <img src={mainImage} height={675} width={360} alt="flyer" className="object-cover rounded-lg border-thin border-neutral-700" />
        ) : null}
      </div>
      <div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
        }} className="items-center block mt-4 space-x-4">
          {images && images.length > 1 ? images.map((image, index) => (
            <img height={300} width={150} key={index} src={image} alt="Thumbnail 1" className="w-16 h-16 cursor-pointer rounded-lg border-thin border-neutral-700 hover:border-blue-700" onClick={() => setMainImage(image)} />
          )) : null}
        </div>
      </div>
    </>
  );
}

export default Thumbnails;
