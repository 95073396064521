import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import LightGallery from 'lightgallery/react';
import GOLDENGATE from '../../assets/images/goldenGate.png';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { Link, useParams } from 'react-router-dom';
import { Get } from '../../api/server';
import moment from 'moment';

const Gallery = () => {
  const { slug } = useParams();
  const [pageData, setPageData] = useState();
  const [nothingToSee, setNothingToSee] = useState(false);


  useEffect(() => {
    async function fetchData() {
      const response = await Get(`/galleries/byslug/${slug}`);
      if (response?.items) {
        setPageData(response);
      } else {
        setNothingToSee(true);
      }
    }
    fetchData();
  }, [slug]);

  const onInit = () => {
    console.log('lightGallery has been initialized');
  };

  const GalleryInfo = () => (
    <Col className='mb-3' style={{ maxHeight: 570, marginBottom: 25 }}>
      <div
        style={{ border: '2px solid #38404e !important', height: 570 }}
        className="relative overflow-hidden rounded-lg shadow-highlight flex flex-col items-center justify-end gap-4 px-6 pb-16 pt-64 text-white image-gallery-card"
      >
        {/* Background Image */}
        <img alt="gate" src={GOLDENGATE} className="w-full h-full object-cover rounded-lg absolute top-0 left-0 z-0 opacity-30" />

        {/* Gradient Overlay */}
        {nothingToSee && (
          <div className="top-0 left-0 w-full h-full bg-gradient-from-b from-gray-800 to-transparent rounded-lg z-10 items-center justify-center">
            <center><h1 className="text-3xl item-center">No Photos at this time</h1></center>
          </div>
        )}
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-800 to-transparent rounded-lg z-10"></div>

        {/* <img style={{ marginBottom: -10, zIndex: 10, width: 130 }} alt="Event Logo" src={pageData?.organization?.logo} width={100} height={'auto'} /> */}

        {/* Title */}
        <h1 className="z-20 mx-auto mb-0 max-w-1xl mt-8 mt-10 text-base font-bold uppercase tracking-widest text-gray-300 sm:text-4xl text-center font-display">
          {pageData?.name}
        </h1>

        {/* Date */}
        <div className="mt-0 z-20 max-w-[40ch] text-white/75 sm:max-w-[32ch]">
          {moment(pageData?.gallery_date).format('ll')}
        </div>


        {/* Button to go back */}
        <Link
          style={{ zIndex: 100 }}
          variant="outline-light"
          className=" mt-6 rounded-lg border border-white bg-white px-3 py-2 text-sm font-semibold text-black transition hover:bg-white/10 hover:text-white md:mt-4"
          to={'/galleries'}
        >
          View More Photos
        </Link>
      </div>
    </Col>
  )

  return (
    <>
      <Container style={{ marginTop: 130 }}>

        {pageData?.items.length > 0 ? (
          <>
            <Row className='gallery-only'>
              <LightGallery
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
                addClass='reset grid grid-cols-1 md:grid-cols-4 gap-6'
                galleryClassName='reset'
                style={{ all: 'unset' }}
                selector={'.image-card-gallery'}
              >
                <GalleryInfo />
                {pageData?.items.map((item, key) => (
                  <div
                    data-src={item}
                    data-href={item}
                    className="relative mb-6 max-h-[570px] rounded-lg overflow-hidden border-2 border-#38404e hover:border-blue-500 transition-all mb-4 image-card-gallery"
                    href={item}
                    style={{ border: '2px solid #38404e', marginBottom: '1rem' }}
                    key={key}
                  >
                    <img
                      alt="Club X"
                      src={item}
                      className="w-full object-cover object-top rounded-lg"
                      width={800}
                      height={600}
                      style={{ height: '575px' }}
                    />
                  </div>
                ))}
              </LightGallery>
            </Row>
          </>
        ) : null}
      </Container >
    </>
  )
}

export default Gallery;