import Thumbnails from '../../components/Thumbnails'
import { Button, Col, Row } from 'react-bootstrap';
import PageContainer from '../../components/PageContainer';
import { Suspense, useContext, useEffect, useState } from 'react';
import { Get } from '../../api/server';
import { useParams } from 'react-router-dom';
import ticketPriceMinMax from '../../helpers/ticketPriceMinMax';
import TicketCart from '../../components/TicketCart';
import GlobalContext from '../../contexts/store';
import { AffContext } from '../../contexts/AffiliateContext';
import trackingLink from '../../services/trackingLink';

export default function Page() {
  const { company } = useContext(GlobalContext);
  const { slug } = useParams();
  const { aff } = useContext(AffContext);
  const [affId, setAffId] = useState(null);
  const [pageData, setPageData] = useState({});
  const [currentTicket, setCurrentTicket] = useState({});
  const [show, setShow] = useState(false);
  const [displayTicketPrices, setDisplayTicketPrices] = useState({});
  const [ticketQuantities, setTicketQuantities] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  useEffect(() => {
    async function getData() {
      try {
        const response = await Get(`/events/getEvent/${slug}`);
        if (response) {
          setPageData(response);
          setCurrentTicket(response);
          setDisplayTicketPrices(ticketPriceMinMax(response?.ticketinventory || []));
        }
      } catch (err) {
        console.log('page error', err);
      }
    }
    getData();
  }, [slug]);

  useEffect(() => {
    async function sendData() {

      try {
        if (aff && pageData?._id) {
          await trackingLink(pageData?._id, aff).then(async (res) => await setAffId(res?._id || null));
        }
      } catch (err) {
        console.log('page error', err);
      }
    }
    sendData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aff, pageData]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <PageContainer>
        <div className="md:pl-20 md:pr-20" style={{ marginTop: 115 }}>
          <div className="rounded-lg border-thin border-neutral-700 bg-black sm:p-0 md:p-5 lg:p-8 dark:bg-black lg:flex-row lg:gap-8">
            <Row style={{ margin: '0px !important' }} className=''>
              <Col md={6}>
                <Thumbnails images={[pageData?.promo?.story || pageData?.promo?.post]} />
              </Col>
              <Col md={6}>
                <div className="flex flex-col p-4">
                  <div className="">
                    <h1 className="text-5xl mb-2">{pageData?.name}</h1>
                    {pageData?.ticketinventory && Array.isArray(pageData?.ticketinventory) && pageData?.ticketinventory.length > 0 ? (
                      <>
                        <h4 className="text-xl mb-4">
                          <span className="py-1 px-3 bg-gray-600 rounded-xl text-white font-medium">Starting at ${displayTicketPrices.minPrice}</span>
                        </h4>
                      </>
                    ) : null}
                  </div>
                  {pageData?.ticketinventory && Array.isArray(pageData?.ticketinventory) && pageData?.ticketinventory.length > 0 ? (
                    <TicketCart
                      className="event-button"
                      show={show}
                      event={pageData}
                      handleClose={handleClose}
                      handleShow={handleShow}
                      currentTicket={currentTicket}
                      company={company}
                      ticketQuantities={ticketQuantities}
                      setTicketQuantities={setTicketQuantities}
                      affId={affId}
                    />
                  ) : (
                    <>
                      {pageData.ticket_link ? (
                        <a style={{ width: '100%' }} target="_BLANK" href={pageData?.ticket_link} rel="noreferrer">
                          <Button color="primary" block className='w-full'>Tickets</Button>
                        </a>
                      ) : <Button color="secondary" disabled block>Unavailable</Button>}
                    </>
                  )}
                  <div className="mb-4 mt-5">
                    <div dangerouslySetInnerHTML={{ __html: pageData?.details }} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </PageContainer>
    </Suspense>

  )
}
