import React from 'react'
import { Link } from 'react-router-dom';

export default function ImageContentCard({ name, image, info, link, position, minH }) {


  const isInternalLink = (url) => {
    // Check if the link is relative (starts with /)
    if (url.startsWith('/')) {
      return true;
    }
    // Check if the link is for the current domain
    const currentDomain = new URL(window.location.href).origin;
    return url.startsWith(currentDomain);
  };

  const RootComponent = () => (
    <div
      style={{ border: '2px solid #38404e', marginBottom: '1rem', height: minH || '300px'}}
      className="relative rounded-lg overflow-hidden hover:border-blue-500 transition-all image-card"
    >
      <a href={link} target="_blank" rel="noreferrer">
        <img
          src={image}
          alt={name || 'content'}
          className={`w-full h-full object-cover rounded-lg ${!position ? 'object-bottom' : 'object-'+position } }`}
        />
      </a>

      <div className="absolute inset-0 flex flex-col items-start justify-end p-4 bg-gradient-to-tr from-black">
        <h5 className="text-white text-xl font-semibold">{name}</h5>
        <p className="text-white text-sm">{info}</p>
      </div>
    </div>
  )

  if (!link) {
    return <RootComponent />
  }

  if (isInternalLink(link)) {
    return (
      <Link to={link}>
        <RootComponent />
      </Link>
    );
  }

  return (
    <a href={link} target='_BLANK' rel="noreferrer">
      <RootComponent />
    </a>
  )
}
