import React from 'react'
import routes from '../routes'
import { Link } from 'react-router-dom'
import LOGO from '../assets/images/KyLogonewmetal.png'
import SideMenu from './SideMenu';
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import TwitterIcon from './TwitterIcon';

export default function MenuBar() {
  return (
    <nav className="absolute pt-16 pl-10 md:pl-16 flex w-full flex items-center absolute justify-between z-20">
          <div className='flex items-center'>
            <img
              src={LOGO}
              width={100}
              height={100}
              alt='logo'
              style={{ marginTop: -25 }}
              className='mr-5'
            />
            <ul className="flex gap-4 hidden md:flex xl:flex lg:flex">
              {routes.map((route, key) => {
                  
                return !route.dynamic ? (
                  <Link
                    key={key}
                    to={route.path}
                    className="text-sm duration-500 text-white hover:text-zinc-500"
                  >
                    {route.name}
                  </Link>
                ) : null
              } )}
            </ul>
          </div>

          <div className='xs:invisible'>

            <ul className="flex gap-2 pr-32 hidden md:flex xl:flex lg:flex">
              <Link
                to={'/'}
                className="text-sm duration-500 text-zinc-200 hover:text-zinc-500"
              >
                <FaFacebookSquare size={24} />
              </Link>

              <Link
                to={'/'}
                className="text-sm duration-500 text-zinc-200 hover:text-zinc-500"
              >
                <FaInstagram size={24} />
              </Link>

              <Link
                to={'/'}
                className="text-sm duration-500 text-zinc-200 hover:text-zinc-500"
              >
                <TwitterIcon size={24} />
              </Link>
            </ul>
            <ul className="flex gap-2 pr-32 md:hidden pr-5 pr-5 z-20">
              <SideMenu />
            </ul>
          </div>
        </nav>
  )
}
