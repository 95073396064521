export default function ticketPriceMinMax(tickets) {
    if (tickets.length === 0) {
      return { minPrice: 0.00, maxPrice: 0.00 };
    }

    let minPrice = tickets[0].price; // Initialize minPrice with the first ticket's price
    let maxPrice = tickets[0].price; // Initialize maxPrice with the first ticket's price

    for (let i = 1; i < tickets.length; i++) {
      const currentPrice = tickets[i].price;
      if (currentPrice < minPrice) {
        minPrice = currentPrice;
      }
      if (currentPrice > maxPrice) {
        maxPrice = currentPrice;
      }
    }

    return { minPrice, maxPrice };
}
