import React, { useContext, useEffect, useState } from 'react'
import { Form, Button, Col, Row } from 'react-bootstrap';
import PageContainer from '../components/PageContainer';
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaLocationArrow } from "@react-icons/all-files/fa/FaLocationArrow";
import TwitterIcon from '../components/TwitterIcon';
import GlobalContext from '../contexts/store';
import { toast } from 'react-toastify';
import { Get } from '../api/server';

export default function Contact() {
  const { company } = useContext(GlobalContext)
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const resetForm = () => {
    setValues({
      first_name: '',
      last_name: '',
      phone: '',
      email: '',
      subject: '',
      message: ''
    });
  }

  const notify = (data, type) => {
    toast(data, {
      type: type || 'default',
      theme: 'dark',
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (values.first_name === '') return notify('Please enter your first name', 'error');
    if (values.email === '') return notify('Please enter your email', 'error');
    if (values.subject === '') return notify('Please enter your subject', 'error');
    if (values.message === '') return notify('Please enter your message', 'error');

    Get('/contacts', {
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
      email: values.email,
      subject: values.subject,
      message: values.message,
      company: company._id
    }).then(() => {
      resetForm();
      notify('Your message has been sent!', 'success');
    })
  }

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <PageContainer>
      <div className="contact-card conatct-page mt-10 md:mt-0">
        <div className="rounded-lg border-thin border-neutral-700 bg-white p-2 dark:bg-black lg:flex-row lg:gap-8">
          <Row>
            <Col md={5}>
              <div className='bg-black p-5 rounded-lg text-white h-full'>
                <h2 className=''>Contact Information</h2>
                <span className='text-md font-thin'>Say something to start a live chat</span>
                <div style={{ marginTop: '30%' }} className=''>
                  <div className='mb-10 flex items-center'><FaPhoneAlt size={26} className='mr-5' /> (707)655-3439</div>
                  <div className='mb-10 flex items-center'><FaEnvelope size={26} className='mr-5' /> Stewart@liveonbeat.com</div>
                  <div className='mb-10 flex items-center'><FaLocationArrow size={26} className='mr-5' /> San Francisco, Bay Area, California. 95132</div>
                </div>
                <div style={{ marginTop: 180 }} className='gap-3 flex items-center'>
                  <span style={{
                    backgroundColor: 'black',
                    borderRadius: 30,
                    padding: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'inline-block',
                  }} className='rasied border border-neutral-800'>
                    <TwitterIcon className='' color='white' size={20} />
                  </span>
                  <span style={{
                    backgroundColor: 'white',
                    borderRadius: 30,
                    padding: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'inline-block',
                  }} className='rasied border border-neutral-800'>
                    <FaInstagram className='' color='black' size={20} />
                  </span>
                  <span style={{
                    backgroundColor: 'black',
                    borderRadius: 30,
                    padding: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'inline-block',
                  }} className='rasied border border-neutral-800'>
                    <FaFacebookF className='' color='white' size={20} />
                  </span>
                </div>
              </div>
            </Col>
            <Col md={7}>
              <div className='px-3 py-5'>
                <Row>
                  <Col md={6} className='mb-10'>
                    <Form.Label>First Name</Form.Label>
                    <input value={values.first_name} onChange={onChange} name='first_name' className='customInput' placeholder="John" />
                    <hr />
                  </Col>
                  <Col md={6} className='mb-10'>
                    <Form.Label>Last Name</Form.Label>
                    <input value={values.last_name} onChange={onChange} name='last_name' className='customInput' placeholder="Doe" />
                    <hr />
                  </Col>
                  <Col md={6} className='mb-10'>
                    <Form.Label>Email</Form.Label>
                    <input value={values.email} onChange={onChange} name='email' className='customInput' type="email" placeholder="yourName@company.com" />
                    <hr />
                  </Col>
                  <Col md={6} className='mb-10'>
                    <Form.Label>Phone Number</Form.Label>
                    <input value={values.phone} onChange={onChange} name='phone' className='customInput' type="tel" placeholder="+102 3456 789" />
                    <hr />
                  </Col>
                  <Col name='subject' md={12} className='mb-10'>
                    <div className='text-black weight-100 mb-2'>Subject</div>
                    <Form.Select
                    name='subject'
                      aria-label="Default select example"
                      onChange={onChange}
                    >
                      <option>Pick a Subject</option>
                      <option value="General Inquiry">General</option>
                      <option value="Events & Tickets">Events & Tickets</option>
                      <option value="Other Inquiry">Other Inquiry</option>
                    </Form.Select>
                  </Col>
                  <Col md={12} className='mb-10'>
                    <Form.Label>Message</Form.Label>
                    <textarea value={values.message} name='message' onChange={onChange} className='customInput' type="tel" placeholder="Tell us your thoughts" />
                    <hr />
                  </Col>
                </Row>

                <Button onClick={handleSubmit} variant="dark" type="submit" className='pull-right'>
                  Send Message
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </PageContainer >
  )
}
