import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GOLDENGATE from '../../assets/images/goldenGate.png';
import LOGO from '../../assets/images/KyLogonewmetal.png';
import { clubx } from '../../util/photos';
import { Link } from 'react-router-dom';
import { Get } from '../../api/server';
import GlobalContext from '../../contexts/store';
import moment from 'moment';

const GalleryList = () => {
  const { company } = useContext(GlobalContext);
  const [pageData, setPageData] = useState(clubx);
  const [nothingToSee, setNothingToSee] = useState(false);

  useEffect(() => {
    async function getData() {
      if (company?._id) {
        const response = await Get(`/galleries/byCompany/byOrg/${company._id}/ky_martinez_events`);
        if (!response?.org) {
          setNothingToSee(true);
        } else {
          setNothingToSee(false);
        }
        setPageData(response);
      }
    }
    getData();
  }, [company]);

  const Gallery = ({ album }) => (
    <div
      style={{ border: '2px solid #38404e !important', height: 570 }}
      className="relative overflow-hidden rounded-lg shadow-highlight flex flex-col items-center justify-end gap-2 px-6 pb-16 text-white image-gallery-card"
    >
      {/* Background Image */}
      <img width={100} height={300} alt="gate" src={album.cover || album.items[0]} className="w-full h-full object-cover rounded-lg absolute top-0 left-0 z-0" />

      {/* Gradient Overlay */}
      {/* <div className="absolute top-0 left-0 w-full h-full bg-gradient-from-b from-gray-800 to-transparent rounded-lg z-10"></div> */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-800 to-transparent rounded-lg z-10"></div>

      <img style={{ marginBottom: -10, zIndex: 10 }} alt="Event Logo" src={LOGO} width={150} height={75} />

      {/* Title */}
      <h1 className="z-20 mx-auto mb-0 max-w-1xl mt-8 mt-0 text-base font-bold uppercase tracking-widest text-gray-300 sm:text-2xl text-center font-display">
        {album.name}
      </h1>

      {/* Date */}
      <div className="mt-0 z-20 max-w-[40ch] text-white/75 sm:max-w-[32ch]">
        {moment(album.gallery_date).format('ll')}
      </div>


      {/* Button to go back */}
      <Link
        className="z-20 pointer z-10 mt-6 rounded-lg border border-white bg-white px-3 py-2 text-sm font-semibold text-black transition hover:bg-white/10 hover:text-white md:mt-4"
        to={`/galleries/${album.slug}`}
      >
        View Gallery
      </Link>
    </div>
  )

  return (
    <>
      <Container style={{ marginTop: 130 }}>
        <Row>
          <Col md={4} className='mb-3' style={{ maxHeight: 570, marginBottom: 25 }}>
            <div
              style={{ border: '2px solid #38404e !important', height: 570 }}
              className="relative overflow-hidden rounded-lg shadow-highlight flex flex-col items-center justify-end gap-4 px-6 pb-16 pt-64 text-white image-gallery-card"
            >
              {/* Background Image */}
              <img alt="gate" src={GOLDENGATE} className="w-full h-full object-cover rounded-lg absolute top-0 left-0 z-0 mt-10 opacity-30" />

              {/* Gradient Overlay */}
              {nothingToSee && (
                <div className="top-0 left-0 w-full h-full bg-gradient-from-b from-gray-800 to-transparent rounded-lg z-10 items-center justify-center">
                  <center><h1 className="text-3xl item-center">No Photos at this time</h1></center>
                </div>
              )}

              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-800 to-transparent rounded-lg z-10"></div>

              <img style={{ marginBottom: -10, zIndex: 10 }} alt="Event Logo" src={LOGO} width={150} height={75} />

              {/* Title */}
              <h1 className="z-20 mx-auto mb-0 max-w-1xl mt-8 mt-0 text-base font-bold uppercase tracking-widest text-gray-300 sm:text-3xl text-center font-display">
                Photo Galleries
              </h1>
            </div>
          </Col>

          {pageData?.galleries && Array.isArray(pageData?.galleries) ? (
            <>
              {
                pageData?.galleries.map((item, key) => (
                  <Col md={4} key={key} className='mb-3' style={{ maxHeight: 570, marginBottom: 25 }}>
                    <Gallery album={item} />
                  </Col>
                ))
              }
            </>
          ) : null}


        </Row>
      </Container>
    </>
  )
}

export default GalleryList;