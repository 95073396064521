import React, { useContext, useState } from 'react';
import { FiMail } from "@react-icons/all-files/fi/FiMail";
import { Get, Post } from '../api/server';
import { toast } from 'react-toastify';
import GlobalContext from '../contexts/store';

const Subscribe = () => {
  const { company } = useContext(GlobalContext)
const [email, setEmail] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault();
    if (email === '') return toast('Please enter your email', 'error');
    Post('/contacts', {
      email: email,
      company: company._id
    }).then(() => {
      setEmail('');
      toast('Thank you for subscribing to our newsletter', 'success');
    })
  }

  return (
    <div className="w-full p-12 text-center subscibe">
      <h1 className="text-2xl mb-4">Subscribe to Our Newsletter</h1>
      <p className="mb-6">Get weekly updates about our product on your email, no spam guaranteed we promise ✌️</p>
      <div className="flex justify-center items-center">
        <div className="relative">
          <FiMail className="absolute left-2 top-1/2 transform -translate-y-1/2" />
          <input 
            type="email" 
            value={email}
            placeholder="Email Address" 
            className="border p-2 pl-8 rounded focus:outline-none focus:border-blue-500"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button onClick={handleSubmit} className="bg-blue-500 text-white rounded ml-2 p-2 px-4 hover:bg-blue-600 focus:outline-none">Subscribe</button>
      </div>
    </div>
  );
}

export default Subscribe;
