import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import {Col, Container, Row } from 'react-bootstrap';
import Ticket from '../../components/Ticket';
import GlobalContext from '../../contexts/store';
import { Get } from '../../api/server';
import BasicHeader from '../../components/BasicHeader';

export default function Conformation() {
  const {company} = useContext(GlobalContext)
  const { user, event } = useParams();
  const [ticketData, setTicketData] = useState([]);

  useEffect(() => {
    async function getCompanyData() {
      console.log('response1')
      if (company?._id && user && event) {
        console.log('response2')
        const response = await Get(`/tickets/getUserTickets/${company._id}/${user}/${event}`);
      console.log(response)
      
      if (response.length > 0) {
        console.log('response3')
        setTicketData(response);
      };
      }
    }

    getCompanyData()
  }, [company._id, event, user]);

  return (
    <>
      <BasicHeader size={48} headerimage={ticketData[0]?.event?.promo?.banner} />
      <div className="spacer"></div>
      <Row>
        <Col className="ml-auto mr-auto text-center">
          <h1 style={{ fontFamily: 'mainFont', fontSize: 35 }} className="title text-white">
            Purchased Tickets
          </h1>
          <h3 style={{ fontFamily: 'mainFont', fontSize: 25 }} className="title text-white">
          {`${ticketData[0]?.event.name} Ticket Confirmation`}
          </h3>
        </Col>
        <Col md={12} className="ml-auto mr-auto text-center">
          <h1 style={{ fontSize: 16 }} className="title text-white p-5">
            We emailed your receipt and link to get back to this page. Please check your spam folder if you do not see it.
          </h1>
        </Col>
      </Row>
      <Container>
        <Row>
          {ticketData.length > 0 ? ticketData.map((ticket, index) => (
            <Col key={index} md={4}>
              <Ticket ticket={ticket} company={company} bgColor={index % 2 === 0 ? '#1f2251' : '#1f2252'} />
            </Col>
          )) : null}
        </Row>
      </Container>

      <div className="spacer"></div>
      <div className="spacer"></div>
    </>
  )
}
