import { Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import MenuBar from './components/MenuBar';
import Particles from './components/particles';
import Landing from './pages/Landing';
import 'sweetalert2/src/sweetalert2.scss'
import './assets/scss/main.scss';
import 'bootstrap/scss/bootstrap.scss';

import routes from './routes';
import Footer from './components/Footer';
import GlobalContext from './contexts/store';
import { useEffect, useState } from 'react';
import getCompany from './services/company';
import '../node_modules/react-toastify/scss/main.scss';
import { ToastContainer } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AffContext } from './contexts/AffiliateContext';


function App() {
  const [company, setcompany] = useState({});
  const [aff, setAff] = useState('');
  const affContexts = { aff, setAff };
  // eslint-disable-next-line no-unused-vars
  const [stripePromise, setStripePromise] = useState(() => loadStripe('pk_test_ERNCnPwW7Sbm9E4BUbPZdZqO', {
    stripeAccount: 'acct_1JrmFMQh82md1QB5'
  }))
  const store = { company, setcompany };

  useEffect(() => {
    async function getInitData() {
      const myCompany = await getCompany();
      setcompany(myCompany);
    }
    getInitData();
  }, []);

  return (
    <GlobalContext.Provider value={store}>
      <AffContext.Provider value={affContexts}>
        <Elements stripe={stripePromise}>
          <Routes>
            <Route path="/" element={<Layout />}>
              {routes.map((route, key) => (
                <Route
                  key={key}
                  path={route.path}
                  element={route.Element}
                  exact={route.exact}
                />
              ))}
              <Route path="*" element={<Landing />} />
            </Route>
          </Routes>
        </Elements>
      </AffContext.Provider>
    </GlobalContext.Provider>
  );
}

const Layout = () => {
  return (
    <>

      <div className="bg-gray-700 text-gray-200">
        <MenuBar />
        <div className='block overflow-hidden bg-gradient-to-tl from-black via-zinc-700/20 to-black'>
          <Particles
            className="absolute inset-0 animate-fade-in"
            quantity={200}
          />
          <div style={{ zIndex: 5 }} className='relative container-margin'>
            <Outlet />
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
              toastClassName="myToast"
            />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
